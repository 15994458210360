* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-weight: 400;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  font-family: "Roboto", sans-serif;
  word-break: break-word;
}

.anticon {
  vertical-align: 3px !important;
}

.ant-collapse-item-active .ant-collapse-header .panel-title {
  color: #e75f3a;
}

.containers {
  padding: 10px 25px 75px 25px;
}
