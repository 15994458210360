.top-header {
  width: 100vw;
}
.header-content {
  height: 55px;
  padding: 0px 15px;
  display: flex;
  background: linear-gradient(180deg, #f0ca68 0%, #e58835 43.29%, #df4a24 100%);

  align-items: center;
  justify-content: space-between;
}
.header-title {
  color: white;
  text-align: center;
  position: absolute;
  right: 0;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 500;
  padding: 5px 20px;
  margin: 0px;
}
.loading-component {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.microsite-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  z-index: 10;
  height: 50px;
  text-align: center;
}
.microsite-footer p {
  color: #939598;
  text-transform: uppercase;
  font-weight: normal;
  font-family: "Roboto", sans-serif;
  font-size: 17px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 0;
}
.microsite-footer p,
.microsite-footer img {
  display: inline-block;
  vertical-align: middle;
}
.microsite-footer img {
  margin-left: 5px;
  margin-right: 5px;
  width: 180px;
}
